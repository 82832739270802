import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import reportsService from 'services/reportsService';
import { downloadFile } from 'utils/FileUtil';

const initialState = {
    reportList : [],
    reportListLoading: false,
    reportCreateLoading : false,
    errorMessage: '',
};

export const createExecutiveReports = createAsyncThunk("user/createExecutiveReports", async (data, {
    rejectWithValue
}) => {
    try {
        return await reportsService.createExecutiveReports(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getExecutiveReports = createAsyncThunk("user/getExecutiveReports", async (data, {
    rejectWithValue
}) => {
    try {
        return await reportsService.getExecutiveReports(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const downloadPdfReport = createAsyncThunk("user/downloadPdfReport", async (data, {
    rejectWithValue
}) => {
    try {
        return await reportsService.downloadPdfReport(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const deleteReport = createAsyncThunk("user/deleteReport", async (data, {
    rejectWithValue
}) => {
    try {
        return await reportsService.deleteReport(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const reportsSlice = createSlice({
    name: 'reportsSlice',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(createExecutiveReports.fulfilled, (state, action) => {
            state.reportCreateLoading = false;
        });

        builder.addCase(createExecutiveReports.pending, (state, action) => {
            state.reportCreateLoading = true;
        });

        builder.addCase(createExecutiveReports.rejected, (state, action) => {
            state.reportCreateLoading = false;
        });

        builder.addCase(getExecutiveReports.fulfilled, (state, action) => {
            state.reportListLoading = false;
            state.reportList = action.payload.data.content
        });

        builder.addCase(getExecutiveReports.pending, (state, action) => {
            state.reportListLoading = true;
        });

        builder.addCase(getExecutiveReports.rejected, (state, action) => {
            state.reportListLoading = false;
        });
        builder.addCase(downloadPdfReport.fulfilled, (state, action) => {
            downloadFile({
                data: action.payload.data,
                fileName: action.meta?.arg?.name
            });
        });

        builder.addCase(deleteReport.fulfilled, (state, action) => {
            state.reportCreateLoading = false;
        });

        builder.addCase(deleteReport.pending, (state, action) => {
            state.reportCreateLoading = true;
        });

        builder.addCase(deleteReport.rejected, (state, action) => {
            state.reportCreateLoading = false;
        });
    }
});

export const {
    setReportsData,
} = reportsSlice.actions;

export default reportsSlice.reducer;