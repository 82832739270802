import API from "../configs/axiosInstance";

const getAllEmailLeakages = (domainName, createdDate) => {
  return API({
    url: `account-service/v1/domain-mail-leakages/pageable`,
    method: "POST",
    data: {
      domainName,
      createdDate,
    },
  });
};

const getScanStatus = (scanId) => {
  return API({
    url: `osint-service/scanStatus`,
    method: "POST",
    data: {
      id: scanId,
    },
  });
};

const getScanSummary = (scanId, by) => {
  return API({
    url: `osint-service/scanSummary`,
    method: "POST",
    data: {
      id: scanId,
      by,
    },
  });
};

const getScanEventResults = (scanId, eventType) => {
  return API({
    url: `osint-service/scanEventResults`,
    method: "POST",
    data: {
      id: scanId,
      eventType,
    },
  });
};


const startEmailLeakageScan = (domainId) => {
  return API({
    url: `account-service/v1/domain-mail-leakages/scan?domainId=${domainId}`,
    method: "POST",
  });
};

const getEmailLeakageService = {
  getAllEmailLeakages,
  getScanStatus,
  getScanSummary,
  getScanEventResults,
  startEmailLeakageScan,
};

export default getEmailLeakageService;
