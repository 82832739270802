import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import tagService from 'services/tagService';

const initialState = {
    allTags:[],
    tagLoading: false,
    assignLoading: false,
};

export const getAllTags = createAsyncThunk("tags/getAllTags", async ( data,{
    rejectWithValue
}) => {
    try {
        return await tagService.getAllTags(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const createTag = createAsyncThunk("tags/createTag", async ( data,{
    rejectWithValue
}) => {
    try {
        await tagService.createTag(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const assignTag = createAsyncThunk("tags/assignTag", async (data,{
    dispatch, rejectWithValue
}) => {
    try {
        await tagService.assignTag(data);
        await dispatch(getAllTags());
    } catch (error) {
    }
});

export const tagSlice = createSlice({
    name: 'tagSlice',
    initialState,
    reducers: {
       
    },
    extraReducers: (builder) => {
        builder.addCase(getAllTags.fulfilled, (state, action) => {
            state.allTags = action.payload.data;
            state.loading = false;
        });
        builder.addCase(getAllTags.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getAllTags.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(createTag.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(createTag.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(createTag.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(assignTag.fulfilled, (state, action) => {
            state.loading = false;
            state.assignLoading = false;
        });
        builder.addCase(assignTag.pending, (state, action) => {
            state.loading = true;
            state.assignLoading = true;
        });
        builder.addCase(assignTag.rejected, (state, action) => {
            state.loading = false;
            state.assignLoading = false;
        });
    }
});

export const {

} = tagSlice.actions;

export default tagSlice.reducer;