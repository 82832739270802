import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import sslService from "../../services/ssl.service";

export const getSsl = createAsyncThunk("ssl/getSsl", async (data,{
    dispatch
}) => {
    try {
        const response = await  sslService.getSsl(null, data?.data);
        dispatch(setFilterRequest(data));
        return response
    } catch (error) {
    }
});

export const getSslDetails = createAsyncThunk("ssl/getSslDetails", async ( data,{
    rejectWithValue
}) => {
    try {
        return await sslService.getSslDetails(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

const initialState = {
    sslList : [],
    sslDetail : null,
    tableLoading: false,
    filterRequest: {},

    defaultFilterRequest: {
        data: {
            domain: "",
            certificateCreateDate: "",
            portNumber: null,
            validFrom: "",
            validTo: "",
            type: "DOMAIN",
        },
        params: {
            page: 0,
            size: 5
        }

    }
};

export const sslSlice = createSlice({
    name: 'sslSlice',
    initialState,
    reducers: {
        setFilterRequest: (state, action) => {
            state.filterRequest = action.payload;
        },
        
    },
    extraReducers: (builder) => {
        builder.addCase(getSsl.fulfilled, (state, action) => {
            state.sslList = action.payload.data;
            state.tableLoading = false;
        });

        builder.addCase(getSsl.pending, (state, action) => {
            state.tableLoading = true;
        });

        builder.addCase(getSsl.rejected, (state, action) => {
            state.tableLoading = false;
        });
        builder.addCase(getSslDetails.fulfilled, (state, action) => {
            state.sslDetail = action.payload.data;
            state.tableLoading = false;
        });

        builder.addCase(getSslDetails.pending, (state, action) => {
            state.sslDetail = null;
            state.tableLoading = true;
        });

        builder.addCase(getSslDetails.rejected, (state, action) => {
            state.tableLoading = false;
        });
    }
});

export const {
    setSslData,
    setFilterRequest,
} = sslSlice.actions;

export default sslSlice.reducer;

