import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { THEME_CONFIG } from "configs/AppConfig";
import themeService from "services/theme.service";

export const config = THEME_CONFIG;

export const getTheme = createAsyncThunk(
  "theme/getTheme",
  async (data, { rejectWithValue }) => {
    try {
      return await themeService.getTheme(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateTheme = createAsyncThunk(
  "theme/updateTheme",
  async (data, { rejectWithValue }) => {
    try {
      return await themeService.updateTheme(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const themeSlice = createSlice({
  name: "theme",
  initialState: {
    ...config,
  },
  filterRequest: {},
  extraReducers: (builder) => {
    builder.addCase(getTheme.fulfilled, (state, action) => {
      if (action.payload.data?.settingData) {
        let data = JSON.parse(action.payload.data.settingData)
        state.navType = data.navType
        state.direction = data.direction
        state.headerNavColor = data.headerNavColor
        state.navCollapsed = true
        state.navType = data.navType
        state.sideNavTheme = data.sideNavTheme
        state.topNavColor = data.topNavColor
        state.currentTheme = data.currentTheme
      } else {
        themeService.createTheme(JSON.stringify(state))
      }
    });
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    toggleCollapsedNav: (state, action) => {
      state.navCollapsed = action.payload;
    },
    onNavStyleChange: (state, action) => {
      state.sideNavTheme = action.payload;
    },
    onLocaleChange: (state, action) => {
      state.locale = action.payload;
    },
    onNavTypeChange: (state, action) => {
      state.navType = action.payload;
    },
    onTopNavColorChange: (state, action) => {
      state.topNavColor = action.payload;
    },
    onHeaderNavColorChange: (state, action) => {
      state.headerNavColor = action.payload;
    },
    onMobileNavToggle: (state, action) => {
      state.mobileNav = action.payload;
    },
    onSwitchTheme: (state, action) => {
      state.currentTheme = action.payload;
    },
    onDirectionChange: (state, action) => {
      state.direction = action.payload;
    },
    onBlankLayout: (state, action) => {
      state.blankLayout = action.payload;
    },
  },
});

export const {
  toggleCollapsedNav,
  onNavStyleChange,
  onLocaleChange,
  onNavTypeChange,
  onTopNavColorChange,
  onHeaderNavColorChange,
  onMobileNavToggle,
  onSwitchTheme,
  onDirectionChange,
  onBlankLayout,
  setLoading,
} = themeSlice.actions;

export default themeSlice.reducer;
