import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import aiService from "services/aiService";

export const getIssueRemedy = createAsyncThunk("domain/getIssueRemedy", async (data, {
    rejectWithValue
}) => {
    try {
        return await aiService.getIssueRemedy(data?.params);
    } catch (eror) {
        return rejectWithValue();
    }
});

const initialState = {
    issueRemedy:null,
    issueRemedyLoading:false,
}

export const aiSlice = createSlice({
    name: 'aiSlice',
    initialState,
    reducers: {
        resetIssueRemedy: (state, action) => {
            state.issueRemedy = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getIssueRemedy.fulfilled, (state, action) => {
            console.log('fulfilled');
            state.issueRemedy = action.payload.data
            state.issueRemedyLoading = false;
        });
        builder.addCase(getIssueRemedy.pending, (state, action) => {
            console.log('pending');
            state.issueRemedy = null;
            state.issueRemedyLoading = true;
        });
        builder.addCase(getIssueRemedy.rejected, (state, action) => {
            console.log('rejected');
            state.issueRemedyLoading = false;
        });

    }
});


export const {
    resetIssueRemedy
} = aiSlice.actions;

export default aiSlice.reducer;