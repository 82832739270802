import API from "../configs/axiosInstance";

const createTheme = (data = {}) => {
  return API({
    url: `/account-service/v1/settings`,
    method: "POST",
    data: {settingsData: data}
  });
};

const updateTheme = (data = {}) => {
  return API({
    url: `/account-service/v1/settings/update `,
    method: "PUT",
    data: {settingsData: data}
  });
};

const getTheme = (data = {}) => {
  return API({
    url: `/account-service/v1/settings/by-user`,
    method: "GET",
  });
};


const themeService = {
  createTheme,
  getTheme,
  updateTheme,
};

export default themeService;
