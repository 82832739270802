import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import contactService from "../../services/contactService";


export const sendDemoForm = createAsyncThunk("task/sendDemoForm", async (data, {
    rejectWithValue
}) => {
    try {
        return await contactService.sendDemoForm(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

const initialState = {
  demoFormLoading:false,
  messageStatus:'success',
  messageContent:'Message!',
};

export const contactSlice = createSlice({
  name: "contactSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendDemoForm.fulfilled, (state, action) => {
      state.demoFormLoading = false;
      state.messageStatus = 'success';
      state.messageContent = 'Form Sent Successfully!'
    });

    builder.addCase(sendDemoForm.pending, (state) => {
      state.demoFormLoading = true;
    });

    builder.addCase(sendDemoForm.rejected, (state) => {
      state.demoFormLoading = false;
      state.messageStatus = 'error';
      state.messageContent = 'Form could not be sent!'
    });
  },
});

export default contactSlice.reducer;
