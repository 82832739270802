import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import scanComparisonService from 'services/scanComparison.service';

const initialState = {
    scanComparison: {},
    scanLoading: false
};


export const getScanComparison = createAsyncThunk("comparison/getScanComparison", async ({ firstScanId, secondScanId }, { rejectWithValue }) => {
    try {
        return await scanComparisonService.getScanComparison(firstScanId, secondScanId);
    } catch (error) {
        return rejectWithValue(error);
    }
})


export const scanComparisonSlice = createSlice({
    name: 'scanComparisonSlice',
    initialState,
    reducers: {
        clearComparison: (state, action) => {
			state.scanComparison = {};
		},
    },
    extraReducers: (builder) => {
        builder.addCase(getScanComparison.fulfilled, (state, action) => {
            state.scanComparison = action.payload.data;
            state.scanLoading = false;
        });
        builder.addCase(getScanComparison.pending, (state, action) => {
            state.scanLoading = true;
        });
        builder.addCase(getScanComparison.rejected, (state, action) => {
            state.scanLoading = false;
        });
    }
});

export const {
    setScanComparison,
    clearComparison
} = scanComparisonSlice.actions;

export default scanComparisonSlice.reducer;