import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import DomainService from "../../services/domain.service";

export const getHistory = createAsyncThunk('domains/getHistory',async (data, {
    rejectWithValue
}) => {
        try {
            return await DomainService.getHistory(data?.data, data?.params);
        } catch (error) {
            console.log(error)
        }
    }
);

const initialState = {
    domains: [],
    historyLoading: false,
}

export const domainHistorySlice = createSlice({
    name: 'domainHistorySlice',
    initialState,
    reducers: {
   
    },
    extraReducers: (builder) => {
        builder.addCase(getHistory.fulfilled, (state, action) => {
            state.domains = action.payload.data
            state.historyLoading = false;
        });
        builder.addCase(getHistory.pending, (state, action) => {
            state.historyLoading = true;
        });
        builder.addCase(getHistory.rejected, (state, action) => {
            state.historyLoading = false;
        });
    }
})

export const {
    setRefreshTable
} = domainHistorySlice.actions;

export default domainHistorySlice.reducer;
