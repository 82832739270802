import { combineReducers } from 'redux';
import theme from './slices/themeSlice';
import auth from './slices/authSlice';
import network from './slices/networkSlice';
import vulnerability from './slices/vulnerabilitySlice';
import domain from './slices/domainSlice';
import userGroups from './slices/userGroupSlice';
import domains from  './slices/domainHistorySlice';
import ssl from './slices/sslSlice';
import browse from './slices/browseSlice';
import performance from './slices/performanceSlice';
import reports from './slices/reportsSlice';
import instant from './slices/instantSlice';
import dashboard from './slices/dashboardSlice';
import task from './slices/taskSlice';
import tags from './slices/tagSlice';
import technology from './slices/technologySlice';
import urlSecurityChecker from './slices/urlSecurityCheckerSlice';
import social from './slices/socialSlice';
import osint from './slices/osintSlice';
import scanComparison from './slices/scanComparisonSlice';
import performanceScheduler from './slices/performanceSchedulerSlice';
import utils from './slices/utilSlice';
import ai from './slices/aiSlice';
import fraud from './slices/fraudSlice';
import contact from './slices/contactSlice';
import news from './slices/newsSlice';
import emailLeakage from './slices/emailLeakageSlice';
import settings from './slices/settingsSlice';

const rootReducer = (asyncReducers) => (state, action) => {
	const combinedReducer = combineReducers({
		theme,
		auth,
		network,
		vulnerability,
		domain,
		userGroups,
		domains,
		ssl,
		browse,
		performance,
		reports,
		instant,
		dashboard,
		task,
		tags,
		technology,
		urlSecurityChecker,
		osint,
		scanComparison,
		social,
		performanceScheduler,
		utils,
		ai,
		fraud,
		contact,
		news,
		emailLeakage,
		settings,
		...asyncReducers,
	});
	return combinedReducer(state, action);
};

export default rootReducer;
