import API from "../configs/axiosInstance";



const sendDemoForm = (data) => {
    return API({
        url:`/account-service/v1/demo-requests`,
        method: 'POST',
        data
    })
}

const contactService = {
    sendDemoForm
};

export default contactService;
