import API from "../configs/axiosInstance";

const signIn = (data = {}) => {
	return API({
		url: `/v1/token/login`,
		method: 'POST',
		data,
	});
};

const signUp = (data = {}) => {
	return API({
		url: '/account-service/v1/register',
		method: 'POST',
		data,
	});
};

const twoFactor = (data) => {
	return API({
		url: `/v1/token?code=${data}`,
		method: 'POST'
	});
};

const login = (data = {}) => {
	return API({
		url: '/account-service/v1/users/current-user',
		method: 'GET',
		data,
		headers: {
			Authorization: `Bearer ${data}`,
		},
	});
};

const getModules = () => {
	return API({
		url: '/account-service/v1/user-groups/permitted-modules',
		method: 'GET',
	});
};

const resetPasswordMail = (data = {}) => {
	return API({
		url: `/account-service/v1/users/start-update-password?email=${data}`,
		method: 'POST',
	});
};

const resetPasswordVerification = (data = {}) => {
	return API({
		url: `/account-service/v1/users/verify-update-password-code?code=${data?.code}&mail=${data?.email}`,
		method: 'POST',
	});
};
const resetPasswordConfirm = (data = {}) => {
	return API({
		url: `/account-service/v1/users/update-password`,
		method: 'PUT',
		data
	});
};

const authService = {
	signIn,
	signUp,
	twoFactor,
	login,
	getModules,
	resetPasswordMail,
	resetPasswordVerification,
	resetPasswordConfirm
};

export default authService;
