import API from "../configs/axiosInstance";

const getTotalIp = () => {
    return API({
        url: '/account-service/v1/dashboards/ip',
        method: 'GET',
    });
}

const getTotalDomain = () => {
    return API({
        url: '/account-service/v1/dashboards/domain',
        method: 'GET',
    });
}

const getDomainIps = () => {
    return API({
        url: '/account-service/v1/dashboards/domain-ips',
        method: 'GET',
    });
}

const getSslExpire = () => {
    return API({
        url: '/account-service/v1/dashboards/ssl-valid-to-dates',
        method: 'GET',
    });
}

const getSubDomain = () => {
    return API({
        url: '/account-service/v1/dashboards/subdomain',
        method: 'GET',
    });
}

const getCategorisedVulnerability = () => {
    return API({
        url: '/account-service/v1/dashboards/critical-level',
        method: 'GET',
    });
}

const getMapDomains = (data = {}) => {
    return API({
        url: '/account-service/v1/dashboards/domain-location',
        method: 'POST',
        data
    });
}

const getOpenedResolvedIssues = () => {
    return API({
        url: '/account-service/v1/dashboards/task-status',
        method: 'GET',
    });
}

const getTopIssues = () => {
    return API({
        url: '/account-service/v1/dashboards/task',
        method: 'GET',
    });
}

const getAssetsWithMostIssues = () => {
    return API({
        url: '/account-service/v1/dashboards/task-critical-level',
        method: 'GET',
    });
}

const getTechnologiesWithMostIssues = () => {
    return API({
        url: '/account-service/v1/domains/technology-category-count',
        method: 'GET',
    });
}

const getPerformanceTestResults = () => {
    return API ({
        url: '/account-service/v1/dashboards/performance-test',
        method: 'GET'
    })
}

const getDashboardDomainsFiltered = (params = {}, data = {}) => {
    return API({
        url: `/account-service/v1/domains/pageable`,
        method: 'POST',
        data,
        params
    });
}

const getCountryInfo = () => {
    return API({
        url: '/account-service/v1/dashboards/country',
        method: 'GET',
    });
}

const dashboardService = {
    getTotalIp,
    getTotalDomain,
    getDomainIps,
    getSslExpire,
    getCountryInfo,
    getSubDomain,
    getCategorisedVulnerability,
    getMapDomains,
    getOpenedResolvedIssues,
    getTopIssues,
    getDashboardDomainsFiltered,
    getAssetsWithMostIssues,
    getTechnologiesWithMostIssues,
    getPerformanceTestResults
}

export default dashboardService;