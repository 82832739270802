import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import sslService from "../../services/urlSecurityCheckerService";

export const getUrlSecurityReport= createAsyncThunk("urlSecurityChecker/getUrlSecurityReport", async ( data,{
    rejectWithValue
}) => {
    try {
        return await sslService.getUrlSecurityReport(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

const initialState = {
    urlReport : '',
    urlSecurityLoading: false,
};

export const urlSecurityCheckerSlice = createSlice({
    name: 'urlSecurityCheckerSlice',
    initialState,
    reducers: {
       
    },
    extraReducers: (builder) => {
        builder.addCase(getUrlSecurityReport.fulfilled, (state, action) => {
            state.urlReport = action.payload.data;
            state.urlSecurityLoading = false;
        });

        builder.addCase(getUrlSecurityReport.pending, (state, action) => {
            state.urlSecurityLoading = true;
        });

        builder.addCase(getUrlSecurityReport.rejected, (state, action) => {
            state.urlSecurityLoading = false;
        });
    }
});

export const {
} = urlSecurityCheckerSlice.actions;

export default urlSecurityCheckerSlice.reducer;

