import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import fraudService from "../../services/fraudService";


export const getFraudDomains = createAsyncThunk(
  "fraud/domains",
  async ({ domainName, createdDate }, { rejectWithValue }) => {
    try {
      const response = await fraudService.getFraudDomains(
        domainName,
        createdDate
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const startFraudScan = createAsyncThunk(
  "fraud/startScan",
  async (domainId, { rejectWithValue }) => {
    try {
      const response = await fraudService.startFraudScan(domainId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const getDomainFrauds = createAsyncThunk(
  "fraud/getDomainFrauds",
  async (domainId, { rejectWithValue }) => {
    try {
      const response = await fraudService.getDomainFrauds(domainId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);



const initialState = {
  fraudDomains: [],
  domainFrauds: null,
  tableLoading: false,
};

export const fraudSlice = createSlice({
  name: "fraudSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFraudDomains.fulfilled, (state, action) => {
      state.fraudDomains = action.payload;
      state.tableLoading = false;
    });

    builder.addCase(getFraudDomains.pending, (state) => {
      state.tableLoading = true;
    });

    builder.addCase(getFraudDomains.rejected, (state) => {
      state.tableLoading = false;
    });

    builder.addCase(startFraudScan.fulfilled, (state) => {
      state.tableLoading = false;
    });

    builder.addCase(startFraudScan.pending, (state) => {
      state.tableLoading = true;
    });

    builder.addCase(startFraudScan.rejected, (state) => {
      state.tableLoading = false;
    });


    builder.addCase(getDomainFrauds.fulfilled, (state, action) => {
      state.tableLoading = false;
      state.domainFrauds = action.payload;
    });

    builder.addCase(getDomainFrauds.pending, (state) => {
      state.tableLoading = true;
    });

    builder.addCase(getDomainFrauds.rejected, (state) => {
      state.tableLoading = false;
    });
  },
});

export default fraudSlice.reducer;
