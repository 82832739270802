import API from "../configs/axiosInstance";

const getCategoryCount = (data) => {
    return API({
        url: `/account-service/v1/domains/technology-category-count`,
        method: 'GET',
        ...data
    });
};

const getTechnologyTable = (data) => {
    return API({
        url: `/account-service/v1/domains/technology`,
        method: 'GET',
        ...data
    });
};

const technologyService = {
    getCategoryCount,
    getTechnologyTable,
};

export default technologyService;
