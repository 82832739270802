import API from "../configs/axiosInstance";

const browseDomain = (data = {}) => {
    return API({
        url: '/osint-service/scanSummary',
        method: 'POST',
        data
    });
}

const getStatus = (data = {}) => {
    return API({
        url:'/osint-service/scanStatus',
        method: 'POST',
        data
    });
}

const getDescription = () => {
    return API ({
        url:'account-service/v1/osint/types-description',
        method: 'GET'
    })
}

const getEventResults = (data = {}) => {
    return API({
        url:'/osint-service/scanEventResults',
        method: 'POST',
        data
    });
}

const startOsintScan =(domainId) => {
    return API({
        url: `account-service/v1/osint/scan`,
        method: "POST",
        data: {
            domainId
        },
    });
}

const getAllOsintResults = (params = {}, data = {}) => {
    return API({
        url: `account-service/v1/osint/pageable`,
        method: "POST",
        data,
        params
    });
};

const osintService = {
    browseDomain,
    getStatus,
    startOsintScan,
    getDescription,
    getAllOsintResults,
    getEventResults
}

export default osintService;