import React, { lazy, Suspense, memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loading from 'components/shared-components/Loading';
import Views from 'views';
import { getTheme, setLoading } from 'store/slices/themeSlice';

const AppLayout = lazy(() => import('./AppLayout'));
const AuthLayout = lazy(() => import('./AuthLayout'));

const Layouts = () => {
	const dispatch = useDispatch();
	const token = useSelector(state => state.auth.token);
	const blankLayout = useSelector(state => state.theme.blankLayout);
	const direction = useSelector(state => state.theme.direction);
	const navType = useSelector(state => state.theme.navType);
	const navCollapsed = useSelector(state => state.theme.navCollapsed);

	const Layout = token && !blankLayout ? AppLayout : AuthLayout
	useEffect(() => {
    if (token) {
      dispatch(setLoading(true))
			dispatch(getTheme())
      dispatch(setLoading(false))
    }
	}, [])
	return (
		<div className='layout'>
		<Suspense fallback={<Loading cover="content"/>}>
			<Layout direction={direction} navType={navType} navCollapsed={navCollapsed}>
				<Views />
			</Layout>
		</Suspense>
		</div>
	)
}

export default memo(Layouts)
