import API from "../configs/axiosInstance";

const getNewsFetchDates = () => {
  return API({
    url: `account-service/v1/news/fetch-dates`,
    method: "GET",
  });
};

const fetchByDomain = (domainId) => {
  return API({
    url: `account-service/v1/news/fetch-by-domain?domainId=${domainId}`,
    method: "POST",
  });
};

const getNews = (title, fetchDate) => {
  return API({
    url: `account-service/v1/news`,
    method: "POST",
    data: {
      title,
      fetchDate,
    },
  });
};

const getDomainNews = () => {
  return API({
    url: `account-service/v1/news/selected-domains-news`,
    method: "GET",
  });
}

const getDomainNewsDetails = (data, params = {}) => {
  return API({
    url: `account-service/v1/news/${data?.domainId}/domain-news-detail?title=${data?.title || ''}`,
    method: "GET",
    params
  });
}

export default {
  getNewsFetchDates,
  getNews,
  getDomainNews,
  getDomainNewsDetails,
  fetchByDomain,
};
