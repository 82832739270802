import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import instantService from "services/instant.service";

export const getAuthorithy = createAsyncThunk("ınstants/getAuthorithy", async (domain, {
    rejectWithValue
}) => {
    try {
        return await instantService.getAuthorithy(domain);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getInstantDns = createAsyncThunk("ınstants/getInstantDns", async (domain, {
    rejectWithValue
}) => {
    try {
        return await instantService.getInstantDns(domain);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getInstantWhois = createAsyncThunk("ınstants/getInstantWhois", async (domain, {
    rejectWithValue
}) => {
    try {
        return await instantService.getInstantWhois(domain);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getInstantIpReputation = createAsyncThunk("ınstants/getInstantIpReputation", async (ip, {
    rejectWithValue
}) => {
    try {
        return await instantService.getInstantIpReputation(ip);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getInstantTechnology = createAsyncThunk("ınstants/getInstantTechnology", async (body, {
    rejectWithValue
}) => {
    try {
        return await instantService.getInstantTechnology(body);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getInstantHttpHeader = createAsyncThunk("ınstants/getInstantHttpHeader", async (domain, {
    rejectWithValue
}) => {
    try {
        return await instantService.getHttpHeader(domain);
    } catch (error) {
        return rejectWithValue(error);
    }
})

export const getInstantScreenshot = createAsyncThunk("ınstants/getInstantScreenshot", async (domain, {
    rejectWithValue
}) => {
    try {
        return await instantService.getScreenshot(domain);
    } catch (error) {
        return rejectWithValue(error);
    }
})

export const getInstantSSLCertificate = createAsyncThunk("ınstants/getInstantSSLCertificate", async (domain, {
    rejectWithValue
}) => {
    try {
        return await instantService.getSSLCertificate(domain);
    } catch (error) {
        return rejectWithValue(error);
    }
})

const initialState = {
    instantLoading: false,
    instantData: '',
    error: null,
};

export const instantSlice = createSlice({
    name: 'instantSlice',
    initialState,
    reducers: {
        clearInstantData: (state, action) => {
            state.instantData = '';
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAuthorithy.fulfilled, (state, action) => {
            state.instantLoading = false;
            state.instantData = action.payload.data;
        });
        builder.addCase(getAuthorithy.pending, (state, action) => {
            state.instantLoading = true;
            state.instantData = '';
        });

        builder.addCase(getAuthorithy.rejected, (state, action) => {
            state.instantLoading = false;
        });

        builder.addCase(getInstantDns.fulfilled, (state, action) => {
            state.instantLoading = false;
            state.instantData = action.payload.data;
        });

        builder.addCase(getInstantDns.pending, (state, action) => {
            state.instantLoading = true;
            state.instantData = '';
        });

        builder.addCase(getInstantWhois.rejected, (state, action) => {
            state.instantLoading = false;
        });

        builder.addCase(getInstantWhois.fulfilled, (state, action) => {
            state.instantLoading = false;
            state.instantData = action.payload.data;
        });

        builder.addCase(getInstantWhois.pending, (state, action) => {
            state.instantLoading = true;
            state.instantData = '';
        });

        builder.addCase(getInstantDns.rejected, (state, action) => {
            state.instantLoading = false;
        });
        builder.addCase(getInstantIpReputation.fulfilled, (state, action) => {
            state.instantLoading = false;
            state.instantData = action.payload.data;
            state.error = '';
        });

        builder.addCase(getInstantIpReputation.pending, (state, action) => {
            state.instantLoading = true;
            state.instantData = '';
            state.error = '';
        });

        builder.addCase(getInstantIpReputation.rejected, (state, action) => {
            state.instantLoading = false;
            state.error = action;
        });

        builder.addCase(getInstantTechnology.rejected, (state, action) => {
            state.instantLoading = false;
        });

        builder.addCase(getInstantTechnology.fulfilled, (state, action) => {
            state.instantLoading = false;
            state.instantData = action.payload.data;
        });

        builder.addCase(getInstantTechnology.pending, (state, action) => {
            state.instantLoading = true;
            state.instantData = '';
        });

        builder.addCase(getInstantHttpHeader.rejected, (state, action) => {
            state.instantLoading = false;
        });

        builder.addCase(getInstantHttpHeader.fulfilled, (state, action) => {
            state.instantLoading = false;
            state.instantData = action.payload.data;
        });

        builder.addCase(getInstantHttpHeader.pending, (state, action) => {
            state.instantLoading = true;
            state.instantData = '';
        });

        builder.addCase(getInstantScreenshot.rejected, (state, action) => {
            state.instantLoading = false;
        });

        builder.addCase(getInstantScreenshot.fulfilled, (state, action) => {
            state.instantLoading = false;
            state.instantData = action.payload.data;
        });

        builder.addCase(getInstantScreenshot.pending, (state, action) => {
            state.instantLoading = true;
            state.instantData = '';
        });

        builder.addCase(getInstantSSLCertificate.rejected, (state, action) => {
            state.instantLoading = false;
        });

        builder.addCase(getInstantSSLCertificate.fulfilled, (state, action) => {
            state.instantLoading = false;
            state.instantData = action.payload.data;
        });

        builder.addCase(getInstantSSLCertificate.pending, (state, action) => {
            state.instantLoading = true;
            state.instantData = '';
        });
    }
});

export const {
    setInstantData,
    clearInstantData,
} = instantSlice.actions;

export default instantSlice.reducer;

