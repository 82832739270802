import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import dashboardService from 'services/dashboardService';

const initialState = {
    totalIp:null,
    totalDomain:null,
    openedResolvedIssues:null,
    assetsWithMostIssues:null,
    technologiesWithMostIssues:null,
    performanceTestResults: null,
    topIssues:null,
    dashboardDomains:null,
    sslExpire : [],
    countryInfo:[],
    domainIps:[],
    countryLoading:false,
    mapLoading:false,
    mapDomains: null,
    technology: [],
    subDomain: null,
    issue : null,
    categorisedVulnerability: null,
    errorMessage: '',
};

export const getTotalIp = createAsyncThunk(
    'dashboard/getTotalIp',
    async () => {
        try {
            return await dashboardService.getTotalIp();
        } catch (error) {
            console.log(error)
        }
    }
);

export const getTotalDomain = createAsyncThunk(
    'dashboard/getTotalDomain',
    async () => {
        try {
            return await dashboardService.getTotalDomain();
        } catch (error) {
            console.log(error)
        }
    }
);

export const getDomainIps = createAsyncThunk(
    'dashboard/getDomainIps',
    async () => {
        try {
            return await dashboardService.getDomainIps();
        } catch (error) {
            console.log(error)
        }
    }
);

export const getSslExpire = createAsyncThunk(
    'dashboard/getSslExpire',
    async () => {
        try {
            return await dashboardService.getSslExpire();
        } catch (error) {
            console.log(error)
        }
    }
);

export const getSubDomain = createAsyncThunk(
    'dashboard/getSubDomain',
    async () => {
        try {
            return await dashboardService.getSubDomain();
        } catch (error) {
            console.log(error)
        }
    }
);

export const getCategorisedVulnerability = createAsyncThunk(
    'dashboard/getCategorisedVulnerability',
    async () => {
        try {
            return await dashboardService.getCategorisedVulnerability();
        } catch (error) {
            console.log(error)
        }
    }
)

export const getOpenedResolvedIssues = createAsyncThunk(
    'dashboard/getOpenedResolvedIssues',
    async () => {
        try {
            return await dashboardService.getOpenedResolvedIssues();
        } catch (error) {
            console.log(error)
        }
    }
)

export const getTopIssues = createAsyncThunk(
    'dashboard/getTopIssues',
    async () => {
        try {
            return await dashboardService.getTopIssues();
        } catch (error) {
            console.log(error)
        }
    }
)

export const getMapDomains = createAsyncThunk(
    'dashboard/getMapDomains', async ( data, {
        rejectWithValue
    }) => {
        try {
            return await dashboardService.getMapDomains(data);
        } catch (error) {
        return rejectWithValue(error);
        }
});

export const getAssetsWithMostIssues = createAsyncThunk(
    'dashboard/getAssetsWithMostIssues',
    async () => {
        try {
            return await dashboardService.getAssetsWithMostIssues();
        } catch (error) {
            console.log(error)
        }
    }
)

export const getPerformanceTestResults = createAsyncThunk(
    'dashboard/getPerformanceTestResults',
    async () => {
        try {
            return await dashboardService.getPerformanceTestResults();
        } catch (error) {
            console.log(error)
        }
    }
)

export const getTechnologiesWithMostIssues = createAsyncThunk(
    'dashboard/getTechnologiesWithMostIssues',
    async () => {
        try {
            return await dashboardService.getTechnologiesWithMostIssues();
        } catch (error) {
            console.log(error)
        }
    }
)

export const getDashboardDomainsFiltered = createAsyncThunk("domain/getDashboardDomainsFiltered", async (data, {
    rejectWithValue
}) => {
    try {
        const response = await dashboardService.getDashboardDomainsFiltered(data?.params, data?.data);
        return response;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getCountryInfo = createAsyncThunk(
    'dashboard/getCountryInfo',
    async () => {
        try {
            return await dashboardService.getCountryInfo();
        } catch (error) {
            console.log(error)
        }
    }
);

export const dashboardSlice = createSlice({
    name: 'dashboardSlice',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getTotalIp.fulfilled, (state, action) => {
            state.totalIp = action.payload.data;
        });
        builder.addCase(getTotalDomain.fulfilled, (state, action) => {
            state.totalDomain = action.payload.data;
        });
        builder.addCase(getDomainIps.fulfilled, (state, action) => {
            state.domainIps = action.payload.data;
        });
        builder.addCase(getSslExpire.fulfilled, (state, action) => {
            state.sslExpire = action.payload.data;
        });
        builder.addCase(getSubDomain.fulfilled, (state, action) => {
            state.subDomain = action.payload.data;
        });
        builder.addCase(getCategorisedVulnerability.fulfilled, (state, action) => {
            state.categorisedVulnerability = action.payload.data;
        });
        builder.addCase(getOpenedResolvedIssues.fulfilled, (state, action) => {
            state.openedResolvedIssues = action.payload.data;
        });
        builder.addCase(getTopIssues.fulfilled, (state, action) => {
            state.topIssues = action.payload.data;
        });
        builder.addCase(getMapDomains.fulfilled, (state, action) => {
            state.mapDomains = action.payload.data;
            state.mapLoading = false;
        });
        builder.addCase(getMapDomains.pending, (state, action) => {
            state.mapDomains = null;
            state.mapLoading = true;
        });
        builder.addCase(getMapDomains.rejected, (state, action) => {
            state.mapLoading = false;
        });
        builder.addCase(getDashboardDomainsFiltered.fulfilled, (state, action) => {
            state.dashboardDomains = action.payload.data.content;
        });
        builder.addCase(getDashboardDomainsFiltered.pending, (state, action) => {
            state.dashboardDomains = null;
        });
        builder.addCase(getAssetsWithMostIssues.fulfilled, (state, action) => {
            state.assetsWithMostIssues = action.payload.data;
        });
        builder.addCase(getTechnologiesWithMostIssues.fulfilled, (state, action) => {
            state.technologiesWithMostIssues = action.payload.data;
        });
        builder.addCase(getPerformanceTestResults.fulfilled,(state, action) => {
            state.performanceTestResults = action.payload.data;
        });
        builder.addCase(getCountryInfo.pending, (state, action) => {
            state.countryLoading = true;
        });
        builder.addCase(getCountryInfo.fulfilled, (state, action) => {
            state.countryInfo = action.payload.data;
            state.countryLoading = false;
        });
        builder.addCase(getCountryInfo.rejected, (state, action) => {
            state.countryLoading = false;
        });
    }
});

export const {
    
} = dashboardSlice.actions;

export default dashboardSlice.reducer;