import API from "../configs/axiosInstance";

const getSchedulerService = (data = {}) => {
    return API({
        url: '/account-service/v1/performance-tests/pageable',
        method: 'POST',
        data: {
            scheduleTypes: ["LOAD_TIME", "PING", "SSL_ANALYZE"],
            domainName: data.domainName || "" // Varsayılan olarak boş bir dize gönderir, eğer verilen bir alan yoksa
        }
    });
}

const formPerformanceSchedulerService = (data = {}) => {
    return API({
        url: '/account-service/v1/performance-tests',
        method: 'POST',
        data,
    });
}


const schedulerService = {
    getSchedulerService,
    formPerformanceSchedulerService,
}

export default schedulerService;