import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import UserGroupService from "../../services/UserGroupService";

export const getAll = createAsyncThunk(
    'userGroups/getAll',
    async () => {
        try {
            return await UserGroupService.getAll();
        } catch (error) {
            console.log(error)
        }
    }
);

export const createUser = createAsyncThunk("user/createUser", async (data, {
    rejectWithValue
}) => {
    try {
        return await UserGroupService.createUser(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const createDepartment = createAsyncThunk("user/createDepartment", async (data, {
    rejectWithValue
}) => {
    try {
        return await UserGroupService.createDepartment(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getModulePermissionList = createAsyncThunk("user/getModulePermissionList", async (data, {
    rejectWithValue
}) => {
    try {
        return await UserGroupService.getModulePermissionList(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const changePermissions = createAsyncThunk("user/changePermissions", async (data, {
    rejectWithValue
}) => {
    try {
        return await UserGroupService.changePermissions(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const deleteUser = createAsyncThunk("user/deleteUser", async (data, {
    rejectWithValue
}) => {
    try {
        return await UserGroupService.deleteUser(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const deleteDepartment = createAsyncThunk("user/deleteDepartment", async (data, {
    rejectWithValue
}) => {
    try {
        return await UserGroupService.deleteDepartment(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

const initialState = {
    users: [],
    userGroups: [],
    loading: false,
    errorMessage: null,
    modulePermissionList: null,
    deleteResult: null,
    changePermissionError:null,
    departmentDeleteResult: null,
}

export const userGroupSlice = createSlice({
    name: 'userGroupSlice',
    initialState,
    reducers: {
        resetPermissionError: (state, action) => {
            state.changePermissionError = null;
        },
        resetDepartmentDeleteResult: (state, action) => {
            state.departmentDeleteResult = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAll.fulfilled, (state, action) => {
            state.userGroups = action.payload.data;
        })
        builder.addCase(createDepartment.fulfilled, (state, action) => {
            if(action?.payload?.response?.data?.error){
                state.errorMessage = action.payload.response.status;
            } else { state.errorMessage = null;}
            state.loading = false;
        })
        builder.addCase(createDepartment.pending, (state, action) => {
            state.loading = true;
            state.errorMessage = null;
        })
        builder.addCase(createDepartment.rejected, (state, action) => {
            state.errorMessage = action.payload.response.data.error;
            state.loading = false;
        })
        builder.addCase(createUser.fulfilled, (state, action) => {
            if(action?.payload?.response?.data?.error){
                state.errorMessage = action.payload.response.status;
            } else { state.errorMessage = null;}
            state.loading = false;
        })
        builder.addCase(createUser.pending, (state, action) => {
            state.loading = true;
            state.errorMessage = null;
        })
        builder.addCase(createUser.rejected, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.response.data.error;
        })
        builder.addCase(getModulePermissionList.pending, (state, action) => {
            state.modulePermissionList = null;
        })
        builder.addCase(getModulePermissionList.fulfilled, (state, action) => {
            state.modulePermissionList = action.payload.data.modules;
        })
        builder.addCase(deleteUser.fulfilled, (state, action) => {
            if (action?.payload?.status === 200) {
                state.deleteResult = "success";
            } else {
                state.deleteResult = "error";
            }
            state.loading = false;
        })
        builder.addCase(deleteUser.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(deleteUser.rejected, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.response.data.error;
        })
        builder.addCase(changePermissions.pending, (state, action) => {
            state.changePermissionError = null;
        })
        builder.addCase(changePermissions.rejected, (state, action) => {
            state.changePermissionError = true;
        })
        builder.addCase(changePermissions.fulfilled, (state, action) => {
            state.changePermissionError = false;
        })
        builder.addCase(deleteDepartment.fulfilled, (state, action) => {
            if (action?.payload?.status === 200) {
                state.departmentDeleteResult = "success";
            } else {
                state.departmentDeleteResult = "error";
            }
            state.loading = false;
        })
        builder.addCase(deleteDepartment.pending, (state, action) => {
            state.loading = true;
        })
    }
})

export const {
    resetPermissionError,
    resetDepartmentDeleteResult,
} = userGroupSlice.actions;

export default userGroupSlice.reducer
