
import API from "../configs/axiosInstance";


const getScanComparison = (firstScanId,secondScanId) => {
    return API({
        url:`account-service/v1/domains/scan-comparison`,
        method: 'GET',
        params: {
            firstScanId: firstScanId,
            secondScanId: secondScanId
        }
    });
}

const scanComparisonService = {
    getScanComparison
}

export default scanComparisonService;