import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authService from '../../services/auth.service';
import CryptoJS from 'crypto-js';
const token = localStorage.getItem('token') || '';
const encryptionKey = process.env.REACT_APP_MODULE_ENCRYPTION_KEY;

export const initialState = {
	loading: false,
	message: '',
	error: '',
	showMessage: false,
	redirect: '',
	messageType: '',
	token: token,
	login: false,
	userMail: null,
	resetPasswordMailData: null,
	resetPasswordVerificationData: null,
	resetPasswordConfirmData: null,
	resetPasswordError: false,
	modulePermissions: undefined,
	user: {
		id: '',
		name: '',
		surname: '',
		email: '',
		phoneNumber: '',
		accountId: '',
		userGroupId: '',
	},
};

export const signUp = createAsyncThunk(
	'signUp',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const response = await authService.signUp(data);
			dispatch(signUpSuccess());
			return response.data;
		} catch (error) {
			if (error.response && error.response.data.status === '50') {
				return rejectWithValue(dispatch(signUpFailure('50')));
			}

			return rejectWithValue(
				dispatch(signUpFailure(error.response?.data?.error || '400'))
			);
		}
	}
);

export const signIn = createAsyncThunk(
	'signIn',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			dispatch(setSignInMail(data?.email));
			const response = await authService.signIn(data);
			if (response.data) {
				const data = response.data;
				dispatch(signInSuccess(data.success));
				return data;
			} else {
				return rejectWithValue(dispatch(signInFailure('10009')));
			}
		} catch (error) {
			if (error.response && error.response.data.status === 403) {
				return rejectWithValue(dispatch(signUpFailure('403')));
			}
			return rejectWithValue(
				dispatch(signUpFailure({
           repeatable: true,
           error: "Account Forbidden !",
           status: "10009"
        }))
			);
		}
	}
);

export const twoFactor = createAsyncThunk(
  "twoFactor",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await authService.twoFactor(data);
      if (response.data) {
        const data = response.data;
        const accessToken = data.accessToken;
        const response2 = await authService.login(accessToken);
        if (!response2.data) {
          return rejectWithValue(
            dispatch(twoFactorFailure("Code is not valid!"))
          );
        }

		dispatch(getModules());

        dispatch(twoFactorSuccess({ accessToken, user: response2?.data }));
		const userId = response2?.data?.id;
		const accountId = response2?.data?.accountId;
		const name = response2?.data?.name;
		const email = response2?.data?.email;
		const userGroupId = response2?.data?.userGroupId;
        localStorage.setItem("token", JSON.stringify({ data }));
        localStorage.setItem("accountId", JSON.stringify({ accountId }));
        localStorage.setItem("name", JSON.stringify({ name }));
        localStorage.setItem("email", JSON.stringify({ email }));
		localStorage.setItem("userGroupId", JSON.stringify({ userGroupId }));
		localStorage.setItem("userId", JSON.stringify({ userId }));
        return data;
      }
	  return rejectWithValue(dispatch(twoFactorFailure("Code is not valid!")));

    } catch (error) {
      return rejectWithValue(dispatch(twoFactorFailure("Code is not valid!")));
    }
  }
);

export const getModules = createAsyncThunk(
	'getModules',
	async (_, { rejectWithValue, dispatch }) => {
		try {
			const response = await authService.getModules();
			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const signInUser = createAsyncThunk(
	'signInUser',
	async (_, { rejectWithValue, dispatch }) => {
		try {
			const parseToken = JSON.parse(token);
			const accessToken = parseToken?.data?.accessToken;
			const response = await authService.login(accessToken);
			if (!response.data) {
				return rejectWithValue(dispatch(signInUserFailture('400')));
			}
			dispatch(signInUserSuccess({ user: response.data }));

			return response.data;
		} catch (error) {
			return rejectWithValue(
				dispatch(signInUserFailture(error.response?.data?.error || '400'))
			);
		}
	}
);

export const signOut = createAsyncThunk(
	'signOut',
	async (_, { rejectWithValue, dispatch }) => {
		try {
			localStorage.removeItem('token');
			localStorage.removeItem('accountId');
			localStorage.removeItem('name');
			localStorage.removeItem('email');
			localStorage.removeItem('userGroupId');
			localStorage.removeItem('userId');
			dispatch(signOutSuccess());
			window.location.reload();
			return true;
		} catch (error) {
			return rejectWithValue(dispatch(signOutFailure(error.message || '400')));
		}
	}
);

export const resetPasswordMail = createAsyncThunk("tags/resetPasswordMail", async ( data,{
    rejectWithValue
}) => {
    try {
        return await authService.resetPasswordMail(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const resetPasswordVerification = createAsyncThunk("tags/resetPasswordVerification", async ( data,{
    rejectWithValue
}) => {
    try {
        return await authService.resetPasswordVerification(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const resetPasswordConfirm = createAsyncThunk("tags/resetPasswordConfirm", async ( data,{
    rejectWithValue
}) => {
    try {
        return await authService.resetPasswordConfirm(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		signUpSuccess: (state, action) => {
			state.loading = false;
			state.redirect = '/auth/signin';
			state.message = '204';
			state.messageType = 'success';
			state.showMessage = true;
			state.error = null;
		},
		signUpFailure: (state, action) => {
			state.loading = false;
			state.message = action.payload;
			state.showMessage = true;
			state.messageType = 'error';
			state.error = null;
		},
		signInSuccess: (state, action) => {
			state.login = action.payload;
			state.loading = false;
			state.redirect = '/auth/two-factor';
			state.message = '200';
			state.messageType = 'success';
			state.showMessage = true;
			state.error = null;
		},
		setSignInMail: (state, action) => {
			state.userMail = action.payload;
		},
		resetLogin: (state, action) => {
			state.login = action.payload;
			state.redirect = '';
			state.error = null;
			state.showMessage = null;
		},
		resetError: (state, action) => {
			state.error = null;
			state.showMessage = false;
			state.message = '';
			state.messageType = '';
		},
		twoFactorSuccess: (state, action) => {
			state.loading = false;
			state.token = action.payload.accessToken;
			state.user = action.payload.user;
			state.redirect = '/';
			state.message = '';
			state.messageType = 'success';
			state.showMessage = true;
			state.error = null;
		},

		twoFactorFailure: (state, action) => {
			state.loading = false;
			state.message = action.payload;
			state.showMessage = true;
			state.messageType = 'error';
			state.error = null;
		},

		signInFailure: (state, action) => {
			state.loading = false;
			state.message = action.payload;
			state.showMessage = true;
			state.messageType = 'error';
			state.error = null;
		},
		signOutFailure: (state, action) => {
			state.message = action.payload;
			state.showMessage = true;
			state.messageType = 'error';
			state.error = null;
		},
		signOutSuccess: (state, action) => {
			state.token = null;
			state.redirect = '/auth/signin';
			state.message = '';
			state.messageType = '';
			state.showMessage = true;
			state.error = null;
			state.user = {
				id: '',
				name: '',
				surname: '',
				email: '',
				phoneNumber: '',
				accountId: '',
				userGroupId: '',
			};
		},
		signInUserSuccess: (state, action) => {
			state.loading = false;
			state.user = action.payload.user;
			state.message = '';
			state.messageType = '';
			state.showMessage = false;
			state.error = null;
		},
		signInUserFailture: (state, action) => {
			state.loading = false;
			state.message = action.payload;
			state.showMessage = true;
			state.messageType = 'error';
			state.error = null;
		},
		setForgotPasswordError: (state, action) => {
			state.resetPasswordError = action.payload;
		},
		decryptModulePermissions: (state, action) => {
			const encryptedModules = localStorage.getItem('modulePermissions');
			if(encryptedModules) {
				const bytes = CryptoJS.AES.decrypt(encryptedModules, process.env.REACT_APP_MODULE_ENCRYPTION_KEY);
				const decryptedState = bytes.toString(CryptoJS.enc.Utf8);
				state.modulePermissions = JSON.parse(decryptedState);
			} else {
				state.modulePermissions = undefined;
			}
		}
	},
	extraReducers: {
		[signUp.pending]: (state, action) => {
			state.loading = true;
		},
		[signUp.fulfilled]: (state, action) => {
			state.loading = false;
		},
		[signUp.rejected]: (state, action) => {
			state.loading = false;
		},
		[signIn.pending]: (state, action) => {
			state.loading = true;
		},
		[signIn.fulfilled]: (state, action) => {
			state.loading = false;
		},
		[signIn.rejected]: (state, action) => {
			if(action.payload && action.payload.repeatable){
				state.error = action.payload.error;
				state.messageType = 'error';
				state.showMessage = true;
				state.loading = false;
			}
		},
		[signOut.pending]: (state, action) => {
			state.loading = true;
		},
		[signOut.fulfilled]: (state, action) => {
			state.loading = false;
		},
		[signOut.rejected]: (state, action) => {
			state.loading = false;
		},
		[signInUser.pending]: (state, action) => {
			state.loading = true;
		},
		[signInUser.fulfilled]: (state, action) => {
			state.loading = false;
		},
		[signInUser.rejected]: (state, action) => {
			state.loading = false;
		},
		[twoFactor.pending]: (state, action) => {
			state.loading = true;
		},
		[twoFactor.fulfilled]: (state, action) => {
			state.loading = false;
		},
		[twoFactor.rejected]: (state, action) => {
			state.loading = false;
		},
		[resetPasswordMail.pending]: (state, action) => {
			state.loading = true;
			state.resetPasswordMailData = null;
			state.resetPasswordError = false;
		},
		[resetPasswordMail.rejected]: (state, action) => {
			state.loading = false;
			state.resetPasswordError = true;
		},
		[resetPasswordMail.fulfilled]: (state, action) => {
			state.loading = false;

			if(action.payload.status === 200) {
				state.resetPasswordMailData = action.payload.data;
			} else {
				state.resetPasswordError = 'Could not found your Cylana account.';
			}
		},
		[resetPasswordVerification.pending]: (state, action) => {
			state.loading = true;
			state.resetPasswordVerificationData = null;
			state.resetPasswordError = false;
		},
		[resetPasswordVerification.rejected]: (state, action) => {
			state.loading = false;
			state.resetPasswordError = true;
		},
		[resetPasswordVerification.fulfilled]: (state, action) => {
			state.loading = false;
			if(action.payload.status === 200) {
				state.resetPasswordVerificationData = action.payload.data;
			} else {
				state.resetPasswordError = 'Wrong verification code!';
			}
		},
		[resetPasswordConfirm.pending]: (state, action) => {
			state.loading = true;
			state.resetPasswordConfirmData = null;
			state.resetPasswordError = false;
		},
		[resetPasswordConfirm.rejected]: (state, action) => {
			state.loading = false;
			state.resetPasswordError = true;
		},
		[resetPasswordConfirm.fulfilled]: (state, action) => {
			state.loading = false;
			if(action.payload.status === 200) {
				state.resetPasswordConfirmData = {success: true}
			} else {
				if(action.payload?.response?.data?.status === "9006") {
					state.resetPasswordError = 'Your new password cannot be same as old password';
				} else {
					state.resetPasswordError = 'Something went wrong!';
				}
			}
		},
		[getModules.pending]: (state, action) => {
			state.modulePermissions = undefined;
			localStorage.removeItem('modulePermissions');
		},
		[getModules.rejected]: (state, action) => {
		},
		[getModules.fulfilled]: (state, action) => {
			const encryptedModules = localStorage.getItem('modulePermissions');
			if(encryptedModules) {
				state.modulePermissions = action.payload?.data?.permittedModules;
				const encryptedState = CryptoJS.AES.encrypt(JSON.stringify(action.payload.data.permittedModules), encryptionKey).toString();
				localStorage.setItem('modulePermissions', encryptedState);
			} else {
				state.modulePermissions = action.payload?.data?.permittedModules;
				const encryptedState = CryptoJS.AES.encrypt(JSON.stringify(action.payload.data.permittedModules), encryptionKey).toString();
				localStorage.setItem('modulePermissions', encryptedState);
				window.location.reload();
			}
		},
	},
});

export const {
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	signOutFailure,
	showLoading,
	signInSuccess,
	setSignInMail,
	signUpSuccess,
	signUpFailure,
	signInFailure,
	signInUserSuccess,
	signInUserFailture,
	resetLogin,
	resetError,
	twoFactorSuccess,
	twoFactorFailure,
	setForgotPasswordError,
	decryptModulePermissions
} = authSlice.actions;

export default authSlice.reducer;
