import API from "../configs/axiosInstance";

const getAllTags = () => {
    return API({
        url: `/account-service/v1/tags/all`,
        method: 'GET',
    });
};

const createTag = (data = {}) => {
    return API({
        url: '/account-service/v1/tags',
        method: 'POST',
        data
    });
}

const assignTag = (data = {}) => {
    return API({
        url: `account-service/v1/tags/assign-tag/${data.type}/${data.id}`,
        method: 'POST',
        data: data.tags
    });
}

const tagService = {
    getAllTags,
    createTag,
    assignTag,
}

export default tagService;