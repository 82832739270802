import API from "../configs/axiosInstance";

const getSsl = (params = {},data = {}) => {
    return API({
        url: `/account-service/v1/domains/certificates`,
        method: 'POST',
        data,
        params
    });
};

const getSslDetails = (id) => {
    return API({
        url: `/account-service/v1/domains/certificates/${id}/details`,
        method: 'GET',
    });
};
const sslService = {
    getSsl,
    getSslDetails,
};

export default sslService;
