import API from "../configs/axiosInstance";

const getIssueRemedy = (params = {}) => {
    return API({
        url: '/account-service/v1/open-ai/remedy-vulnerability',
        method: 'GET',
        params
    })
}


const aiService = {
    getIssueRemedy
};

export default aiService;