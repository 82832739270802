import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import emailLeakageService from "../../services/emailLeakageService";

export const getAllEmailLeakages = createAsyncThunk(
  "emailLeakage/allEmailLeakages",
  async ({ domainName, createdDate }, { rejectWithValue }) => {
    try {
      const response = await emailLeakageService.getAllEmailLeakages(
        domainName,
        createdDate
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getScanStatus = createAsyncThunk(
  "emailLeakage/scanStatus",
  async (scanId, { rejectWithValue }) => {
    try {
      const response = await emailLeakageService.getScanStatus(scanId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getScanSummary = createAsyncThunk(
  "emailLeakage/scanSummary",
  async ({ scanId, by }, { rejectWithValue }) => {
    try {
      const response = await emailLeakageService.getScanSummary(scanId, by);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getScanEventResults = createAsyncThunk(
  "emailLeakage/scanEventResults",
  async ({ scanId, eventType }, { rejectWithValue }) => {
    try {
      const response = await emailLeakageService.getScanEventResults(
        scanId,
        eventType
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const startEmailLeakageScan = createAsyncThunk(
  "emailLeakage/startEmailLeakageScan",
  async (domainId, { rejectWithValue }) => {
    try {
      const response = await emailLeakageService.startEmailLeakageScan(
        domainId
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  emailLeakages: [],
  emailLeakageLoading: false,
  scanStatus: {},
  scanSummary: {},
  scanEventResults: [],
  selectedEmailLeakage: {},
  emailLeakageName: "",
  detailLoading: false,
  canRefresh:false
};

export const emailLeakageSlice = createSlice({
  name: "emailLeakageSlice",
  initialState,
  reducers: {
    selectedEmailLeakage: (state, action) => {
      state.selectedEmailLeakage = action.payload;
    },
    emailLeakageName: (state, action) => {
      state.emailLeakageName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllEmailLeakages.fulfilled, (state, action) => {
      state.emailLeakages = action.payload.content;
      state.emailLeakageLoading = false;
    });

    builder.addCase(getAllEmailLeakages.pending, (state) => {
      state.emailLeakages = [];
      state.emailLeakageLoading = true;
    });

    builder.addCase(getAllEmailLeakages.rejected, (state) => {
      state.emailLeakageLoading = false;
    });

    builder.addCase(getScanStatus.fulfilled, (state, action) => {
      state.scanStatus = action.payload;
      state.detailLoading = false;
    });

    builder.addCase(getScanStatus.rejected, (state) => {
      state.scanStatus = {};
    });

    builder.addCase(getScanStatus.pending, (state) => {
      state.scanStatus = {};
      state.detailLoading = true;
    });

    builder.addCase(getScanSummary.fulfilled, (state, action) => {
      state.scanSummary = action.payload;
      state.detailLoading = false;
    });

    builder.addCase(getScanSummary.pending, (state) => {
      state.detailLoading = true;
    });

    builder.addCase(getScanSummary.rejected, (state) => {
      state.detailLoading = false;
    });

    builder.addCase(getScanEventResults.fulfilled, (state, action) => {
      state.scanEventResults = action.payload;
      state.detailLoading = false;
    });

    builder.addCase(getScanEventResults.rejected, (state) => {
      state.scanEventResults = [];
    });

    builder.addCase(getScanEventResults.pending, (state) => {
      state.scanEventResults = [];
      state.detailLoading = true;
    });
    builder.addCase(startEmailLeakageScan.fulfilled, (state) => {
      state.canRefresh = true;
    });
    builder.addCase(startEmailLeakageScan.rejected, (state) => {
      state.canRefresh = false;
    });
    builder.addCase(startEmailLeakageScan.pending, (state) => {
      state.canRefresh = false;
    });

  },
});

export const {
  selectedEmailLeakage,
  scanStatus,
  scanSummary,
  emailLeakages,
  emailLeakageName,
} = emailLeakageSlice.actions;

export default emailLeakageSlice.reducer;
