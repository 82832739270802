import API from "../configs/axiosInstance";

const getUrlSecurityReport = (url) => {
    return API({
        url: `/account-service/v1/website-security-checkers?website=${url}`,
        method: 'GET',
    });
};
const urlSecurityCheckerService = {
    getUrlSecurityReport,
};

export default urlSecurityCheckerService;