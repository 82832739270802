import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import store from './store';
import history from './history';
import Layouts from './v2/layouts';
import { THEME_CONFIG } from './configs/AppConfig';
import './lang';
import './v2/styles/layout/fonts.css';

const loadThemeFile = async (theme) => {
  try {
    const manifest = await fetch('/css/hash-manifest.json').then((response) =>
        response.json()
    );
    return manifest[`${theme}-theme.css`] || '';
  } catch (error) {
    console.error('Error loading manifest:', error);
    return '';
  }
};

function App() {
  const [darkTheme, setDarkTheme] = useState('');
  const [lightTheme, setLightTheme] = useState('');

  useEffect(() => {
    loadThemeFile('dark').then(setDarkTheme);
    loadThemeFile('light').then(setLightTheme);
  }, []);

  if (!darkTheme || !lightTheme) {
    return null;
  }

  return (
      <div className="App">
        <Provider store={store}>
          <BrowserRouter history={history}>
            <ThemeSwitcherProvider
                themeMap={{
                  dark: `/css/${darkTheme}`,
                  light: `/css/${lightTheme}`,
                }}
                defaultTheme={THEME_CONFIG.currentTheme}
                insertionPoint="styles-insertion-point"
            >
              <Layouts />
            </ThemeSwitcherProvider>
          </BrowserRouter>
        </Provider>
      </div>
  );
}

export default App;
