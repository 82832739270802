import axios from "axios";
import { env } from "./EnvironmentConfig";

const API = axios.create({
  baseURL: env.API_ENDPOINT_URL,
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("token")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("token")).data.accessToken
    }`;
  }
  return req;
});
API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("token")
      window.location.href = '/login'
    }
    return error;
  }
);

export default API;
