import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

const initialState = {
    isMobileResponsive: false,
    detailTabMenu:'asset-info',
    detailSideTabMenu:'basic-information',
    assetListTabMenu:null,
};

export const utilSlice = createSlice({
    name: 'utilSlice',
    initialState,
    reducers: {
        setResponsive: (state, action) => {
            state.isMobileResponsive = action.payload;
        },
        setdetailTabMenu: (state, action) => {
            state.detailTabMenu = action.payload;
        },
        setDetailSideTabMenu: (state, action) => {
            state.detailSideTabMenu = action.payload;
        },
        setAssetListTabMenu: (state, action) => {
            state.assetListTabMenu = action.payload;
        },        
    },
    extraReducers: (builder) => {
       
    }
});

export const {
    setResponsive,
    setdetailTabMenu,
    setDetailSideTabMenu,
    setAssetListTabMenu,
} = utilSlice.actions;

export default utilSlice.reducer;

