import API from "../configs/axiosInstance";

const getAll = () => {
    return API({
        url: '/account-service/v1/user-groups/all',
        method: 'GET'
    });
}

const createUser = (data = {}) => {
    return API({
        url: `/account-service/v1/users`,
        method: 'POST',
        data
    });
};

const createDepartment = (data = {}) => {
    return API({
        url: '/account-service/v1/user-groups',
        method: 'POST',
        data
    });
}

const getModulePermissionList = () => {
    return API({
        url: '/account-service/v1/user-groups/modules',
        method: 'GET',
    });
}

const deleteUser = (id) => {
    return API({
        url: `/account-service/v1/users/${id}`,
        method: 'DELETE'
    });
}

const deleteDepartment = (id) => {
    return API({
        url: `/account-service/v1/user-groups/${id}`,
        method: 'DELETE'
    });
}

const changePermissions = (data) => {
    return API({
        url: '/account-service/v1/user-groups/permitted-modules',
        method: 'PUT',
        data
    });
}


const userGroupService = {
    getAll,
    createUser,
    createDepartment,
    getModulePermissionList,
    deleteUser,
    deleteDepartment,
    changePermissions
}

export default userGroupService;
