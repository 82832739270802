import API from "../configs/axiosInstance";

const getFraudDomains = (domainName, createdDate) => {
  return API({
    url: `account-service/v1/fraud-domains/all`,
    method: "POST",
    data: {
      domainName,
      createdDate,
    },
  });
};

const startFraudScan = (domainId) => {
  return API({
    url: `account-service/v1/fraud-domains/scan?domainId=${domainId}`,
    method: "POST",
  });
}


const getDomainFrauds = (domainId) => {
  return API({
    url: `account-service/v1/fraud-domains/${domainId}/by-domain`,
    method: "GET",
  });
}

const fraudService = {
  getFraudDomains,
  startFraudScan,
  getDomainFrauds
};

export default fraudService;
