import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import schedulerService from 'services/scheduler.service';

const initialState = {
    scheduler: [],
    performanceSchedulerTableLoading: false
};

export const getPerformanceScheduler = createAsyncThunk("getPerformanceScheduler/getScheduler", async ( data, {
    rejectWithValue
}) => {
    try {
        return await schedulerService.getSchedulerService(data);
    } catch (error) {
        return rejectWithValue(error);
    }
})

export const formPerformanceScheduler = createAsyncThunk("formPerformanceScheduler/Scheduler", async ( data, {
    rejectWithValue
}) => {
    try {
        return await schedulerService.formPerformanceSchedulerService(data);
    } catch (error) {
        return rejectWithValue(error);
    }
})


export const PerformanceSchedulerSlice = createSlice({
    name: 'PerformanceSchedulerSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getPerformanceScheduler.fulfilled, (state, action) => {
            state.scheduler = action.payload.data.content;
            state.performanceSchedulerTableLoading = false;
        });
        builder.addCase(getPerformanceScheduler.pending, (state, action) => {
            state.performanceSchedulerTableLoading = true;
        });
        builder.addCase(getPerformanceScheduler.rejected, (state, action) => {
            state.performanceSchedulerTableLoading = false;
        });
    }
});

export const {
    setDomainName
} = PerformanceSchedulerSlice.actions;

export default PerformanceSchedulerSlice.reducer;