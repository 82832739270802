import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import browseService from "../../services/browse.service";

export const getBrowseUrl = createAsyncThunk("browse/url", async (selectedDomain, {rejectWithValue}) => {
    try {
        return await browseService.getBrowseUrl(selectedDomain);
    } catch (error) {
        return rejectWithValue(error);
    }
});

const initialState = {
    urlList : [],
    tableLoading: false,
};

export const browseSlice = createSlice({
    name: 'browseSlice',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getBrowseUrl.fulfilled, (state, action) => {
            state.urlList = action.payload.data;
            state.tableLoading = false;
            // console.log(action.payload.data)
        });

        builder.addCase(getBrowseUrl.pending, (state, action) => {
            state.tableLoading = true;
        });

        builder.addCase(getBrowseUrl.rejected, (state, action) => {
            state.tableLoading = false;
        });
    }
});

export const {
    setBrowseData,
} = browseSlice.actions;

export default browseSlice.reducer;

