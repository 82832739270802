import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import technologyService from "services/technology.service";

export const getCategoryCount = createAsyncThunk("technology/getCategoryCount", async ( data,{
    rejectWithValue
}) => {
    try {
        return await technologyService.getCategoryCount(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getTechnologyTable = createAsyncThunk("technology/getTechnologyTable", async ( data,{
    rejectWithValue
}) => {
    try {
        return await technologyService.getTechnologyTable(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});


const initialState = {
    categoryCount : [],
    technologyTable : [],
    tableLoading: false,
    categoryLoading: false,
};

export const technologySlice = createSlice({
    name: 'technologySlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCategoryCount.fulfilled, (state, action) => {
            state.categoryCount = action.payload.data;
            state.categoryLoading = false;
        });

        builder.addCase(getCategoryCount.pending, (state, action) => {
            state.categoryLoading = true;
        });

        builder.addCase(getCategoryCount.rejected, (state, action) => {
            state.categoryLoading = false;
        });
        builder.addCase(getTechnologyTable.fulfilled, (state, action) => {
            state.technologyTable = action.payload.data;
            state.tableLoading = false;
        });

        builder.addCase(getTechnologyTable.pending, (state, action) => {
            state.tableLoading = true;
            state.technologyTable = [];
        });

        builder.addCase(getTechnologyTable.rejected, (state, action) => {
            state.tableLoading = false;
        });
    }
});

export const {
    setPerformanceData,
} = technologySlice.actions;

export default technologySlice.reducer;

