import API from "../configs/axiosInstance";

const pingTest = (domainId) => {
    return API({
        url: `account-service/v1/performance-tests/ping/${domainId}`,
        method: 'POST',
        
    });
};

const LoadTimeTest = (domainId) => {
    return API({
        url: `account-service/v1/performance-tests/load-time/${domainId}`,
        method: 'POST',
        
    });
};

const sslAnalyze = (domainId) => {
    return API({
        url: `account-service/v1/ssl-analyze/${domainId}`,
        method: 'POST',
        
    });
};

const getSslAnalyze = (data) => {
    return API({
        url: `account-service/v1/ssl-analyze/domain-id/${data?.value}`,
        method: 'GET',
        params: {
            page: data?.currentPage,
            size: data?.pageSize
        },
    });
};

const getSslAnalysisTestPageable = () => {
    return API({
        url: `account-service/v1/ssl-analyze/pageable`,
        method: 'GET',
        
    });
};

const getPingTestResult = (domainId) => {
    return API({
        url: `account-service/v1/performance-tests/pings/${domainId}`,
        method: 'GET',
        
    });
};

const getLoadTimeTestResult = (domainId) => {
    return API({
        url: `account-service/v1/performance-tests/load-times/${domainId}`,
        method: 'GET',
        
    });
};

const getDomainsWithPerformanceTest = (data = {}) => {
    return API({
        url: `account-service/v1/domains/performance-test-domains`,
        method: 'POST',
        data: data,
    });
}

const performanceService = {
    pingTest,
    LoadTimeTest,
    sslAnalyze,
    getSslAnalyze,
    getPingTestResult,
    getSslAnalysisTestPageable,
    getLoadTimeTestResult,
    getDomainsWithPerformanceTest
};

export default performanceService;