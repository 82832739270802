import API from "../configs/axiosInstance";

const getBrowseUrl = (selectedDomain) => {
    return API({
        url: `account-service/v1/way-back-machines?domain=${selectedDomain}`,
        method: 'GET',
    });
};

const browseService = {
    getBrowseUrl,
};

export default browseService;
