import API from "../configs/axiosInstance";

const createExecutiveReports = (data = {}) => {
    return API({
        url: '/account-service/v1/domain-reports/create',
        method: 'POST',
        data
    });
}

const getExecutiveReports = (data = {}) => {
    return API({
        url: '/account-service/v1/domain-reports/pageable',
        method: 'POST',
        data
    });
}

const downloadPdfReport = (data) => {
    return API({
        url: `account-service/v1/domain-reports/download/${data.id}`,
        method: 'POST',
        responseType: 'arraybuffer',
        data
    });
}

const deleteReport = (data) => {
    return API({
        url: `/account-service/v1/domain-reports/${data.id}`,
        method: 'DELETE',
        data
    });
}

const reportsService = {
    createExecutiveReports,
    getExecutiveReports,
    downloadPdfReport,
    deleteReport
}

export default reportsService;