import React, { useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';
import Routes from 'routes';
import { resources } from 'lang';
import useBodyClass from 'utils/hooks/useBodyClass';
import { useDispatch } from 'react-redux';
import i18n from 'i18next'
import { onLocaleChange } from 'store/slices/themeSlice';
import moment from "moment";
import 'moment/locale/tr';
import { useThemeSwitcher } from 'react-css-theme-switcher';

export const Views = () => {
	const dispatch = useDispatch();
	const { locale, direction, currentTheme } = useSelector((state) => state.theme);
	const currentAppLocale = resources[locale];
	const token = localStorage.getItem('token');
	useBodyClass(`dir-${direction}`);
	const { switcher, themes } = useThemeSwitcher();
	useEffect(() => {
		switcher({ theme: themes[currentTheme] });
	}, [currentTheme])
	useEffect(() => {
		if (window === undefined) {
			return;
		}
		const root = window.document.documentElement;
		root.setAttribute('dir', direction);
		if (localStorage.getItem('lang')) {
			dispatch(onLocaleChange(localStorage.getItem('lang')))
			i18n.changeLanguage(localStorage.getItem('lang'))
			if (localStorage.getItem('lang') === "tr") {
				moment.locale('tr')
			} else {
				moment.locale('en')
			}
		} else {
			localStorage.setItem('lang', "en");
			dispatch(onLocaleChange("en"))
			i18n.changeLanguage("en")

		}

	}, [direction, token]);
	
	return (
		<ConfigProvider direction={direction} locale={currentAppLocale.antd}>
			<Routes />
		</ConfigProvider>
	);
};

export default Views;
