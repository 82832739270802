import API from "../configs/axiosInstance";

const socialScan = (data) => {
    return API({
        url: `account-service/v1/social-searcher`,
        method: 'POST',
        data
    });
}

const socialSearcher = (data) => {
    return API({
        url: `account-service/v1/social-searcher/all`,
        method: 'POST',
        data
    });
}

const socialScanTrigger = (id) => {
    return API({
        url: `account-service/v1/social-searcher/scan-trigger/${id}`,
        method: 'POST'
    });
}

const socialScanResultsById = (id) => {
    return API({
        url: `account-service/v1/social-searcher/${id}/posts`,
        method: 'GET'
    });
}



const socialService = {
    socialScan,
    socialSearcher,
    socialScanTrigger,
    socialScanResultsById,
}

export default socialService;